@import url(https://weloveiconfonts.com/api/?family=fontawesome);
.mytable th{
    font-weight: normal;
}

.tab-list {
    border-bottom: 1px solid #ccc;
    padding-left: 0;
}

.tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
}

.tab-list-active {
    background-color: white;
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
}

[class*="fontawesome-"]:before {
    font-family: 'fontawesome', sans-serif;
}

body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.gray-body {
    background-color: #F8F9FA;
}

.select-search-box {
    width: 300px;
    position: relative;
    margin-bottom: 20px;
    background: #fff;
    border-radius: 4px;
}

.select-search-box--multiple {
    border-radius: 4px;
    box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    overflow: hidden;
}

.select-search-box::after {
    font-family: fontawesome;
    content:"\f078";
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    text-align: center;
    line-height: 50px;
    color: #222f3e;
    z-index: 1;
}

.select-search-box--multiple::after, .select-search-box--focus::after {
    content:"\f002";
}

.select-search-box__icon--disabled::after {
    content: none !important;
}

.select-search-box--input::after {
    display: none !important;
}

.select-search-box__out {
    display: none;
}

.select-search-box__search {
    display: block;
    width: 100%;
    height: 50px;
    border: none;
    background: none;
    outline: none;
    font-size: 16px;
    padding: 0 10px;
    color: #222f3e;
    -webkit-appearance: none;
    box-sizing: border-box;
    position: relative;
    z-index: 2;
    cursor: pointer;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 50px;
    box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
}

input.select-search-box__search {
    line-height: 1;
}

.select-search-box--multiple .select-search-box__search {
    box-shadow: none;
}

.select-search-box--input .select-search-box__search {
    cursor: text;
}

.select-search-box__search:focus {
    cursor: text;
}

.select-search-box__search--placeholder {
    font-style: italic;
    font-weight: normal;
}

.select-search-box input::-webkit-input-placeholder {
    color: #ccc;
    font-style: italic;
    font-weight: normal;
}

.select-search-box input::-moz-placeholder {
    color: #ccc;
    font-style: italic;
    font-weight: normal;
}

.select-search-box input:-moz-placeholder {
    color: #ccc;
    font-style: italic;
    font-weight: normal;
}

.select-search-box input:-ms-input-placeholder {
    color: #ccc;
    font-style: italic;
    font-weight: normal;
}

input[type='search']::-webkit-search-cancel-button, input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

.select-search-box__select {
    display: none;
    position: absolute;
    top: 40px;
    left: 10px;
    right: 0;
    background: #ffffff;
    border-radius: 4px;
    overflow: auto;
    box-shadow: 0 7px 14px 0 rgba(50, 50, 93, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
    z-index: 100;
    min-height: 49px;
}

.select-search-box--multiple .select-search-box__select {
    display: block;
    position: static;
    border-top: 1px solid #eee;
    border-radius: 0;
    box-shadow: none;
}

.select-search-box__select--display {
    display: block;
}

.select-search-box__option {
    font-size: 14px;
    font-weight: 400;
    color: #d6efff;
    padding: 10px 10px;
    border-top: 1px solid #eee;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.select-search-box__option:first-child {
    border-top: none;
}

.select-search-box__option--hover, .select-search-box__option:hover {
    background: #f4f7fa;
}

.select-search-box__option--selected {
    background: #54A0FF;
    color: #fff;
    border-top-color: #2184ff;
}

.select-search-box__option--selected:hover, .select-search-box__option--selected.select-search-box__option--hover {
    background: #2184ff;
    color: #fff;
    border-top-color: #2184ff;
}

.select-search-box__group {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #eee;
    position: relative;
}

.select-search-box__group-header {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background: white;
    padding: 0 10px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
}

.bottom-right{
    position: fixed;
    bottom: 10%;
    right: 10%;
    text-align: center;
    box-shadow: 0 2px 2px #888888;
    -webkit-transition: color 0.3s, background-image 0.5s, ease-in-out;
    transition: color 0.3s, background-image 0.5s, ease-in-out;
    z-index:999;
}


#heart {
    color: grey;
    font-size: 20px;
    background-color: transparent;
    border-color: transparent;
}

#red-heart {
    color: red;
    font-size: 20px;
    background-color: transparent;
    border-color: transparent;
}


.form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
}
.form-signin .checkbox {
    font-weight: 400;
}
.form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
}
.form-signin .form-control:focus {
    z-index: 2;
}
.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

#logged .form-control-dark {
    color: #252628;
    background-color: rgba(255, 255, 255, 0.77);
    border-color: rgba(255, 255, 255, 0.55);
}

#logged .form-control{
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
}

#logged .navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, .25);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 48px 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-sticky {
        position: -webkit-sticky;
        position: sticky;
    }
}

.sidebar .nav-link {
    font-weight: 500;
    color: #333;
}

.sidebar .nav-link .feather {
    margin-right: 4px;
    color: #999;
}

.sidebar .nav-link.active {
    color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
    color: inherit;
}

.sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
}

[role="main"] {
    padding-top: 133px; /* Space for fixed navbar */
}

@media (min-width: 768px) {
    [role="main"] {
        padding-top: 48px; /* Space for fixed navbar */
    }
}

.related-company{
    font-weight: 600;
    color:green;
}

.nav-black{
    background-color: #292b2c;
}

#side_nav .nav-link  {
    color: #999;
}

#side_nav .nav-link.active {
    color: #ffffff;
}



